<template>
  <div>
    <custom-breadcrumb
          :pageTitle="$t('models.licence')"
          :breadcrumb="[
            {
              text: $t('Licences'),
              to: 'licences'
            },
            {
              text: $t('Detail'),
              active: true,
            },
          ]"
      ></custom-breadcrumb>
    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <licence-view-info-card 
            :user-data="userData"
            @refetch-data="getData(userData.id)"
          />
        <b-card-actions
            v-if="$can('manage', 'all')"
            title="SSO"
            ref="refreshCard"
            action-refresh
            @refresh="refreshSSO('refreshCard')"
            >
          <b-card-sub-title>{{userData.sso}}</b-card-sub-title>
        </b-card-actions>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >

          <licence-view-permissions-card
          :user-data="userData"
          :permissionData="permissionData" 
          />
        </b-col>
      </b-row>

    </template>
    
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      v-else
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'licences'}"
        >
          Licence List
        </b-link>
        for other users.
      </div>
    </b-alert>
  
    <licence-view-payments-card 
      v-if="userData"
      :payments="userData.payment"/>
  
  </div>
</template>

<script>
import router from '@/router'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'

import LicenceViewInfoCard from './view/InfoCard.vue'
import LicenceViewPermissionsCard from './view/PermissionsCard.vue'
import LicenceViewPaymentsCard from './view/PaymentsCard.vue'
import {
  BRow, BCol, BAlert, BLink,
  BCard,
  BCardTitle,
  BCardSubTitle,
} from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'

export default {
  name:"LicencesView",
  components:{
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardActions,
    
    CustomBreadcrumb,
    LicenceViewInfoCard,
    LicenceViewPermissionsCard,
    LicenceViewPaymentsCard,
    BCardActions,
  },
  data(){
    return {
      id: null,
      userData: null,
      permissionData: null,
    }
  },
  mounted(){
    let id = router.currentRoute.params.id;
    this.id = id;
    console.log(this.id)
    this.getData(id)
  },
  methods: {
    async refreshSSO(cardName) {
      this.$refs[cardName].showLoading = false
      let data = {
        'id': this.id
      }
      await this.$http.post('regenerateSSO', data).then( response =>{
          console.log(response)
          this.userData['sso'] = response.data.new_sso;
      }).catch(error => {
          console.log("Err -> ", error)
      }) 

},
    async getData(id){
        console.log("loading")
        let data = {
          'id': id
        }
        await this.$http.post('fetchLicence', data).then( response =>{
            console.log(response)
            this.userData = response.data.user;
            this.permissionData = response.data.permissionData;
            //this.setData(response);
        }).catch(error => {
            console.log("Err -> ", error)
        })
    },
  }

}
</script>

<style>

</style>